@use "../../flex_custom.scss" as *;

.container {
  @include center_center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;
  z-index: 1;
}
