html {
  scroll-behavior: smooth;
}
body {
  background-color: black;
}
.scroll-container {
  height: 100vh;
  min-height: 450px;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.js-scroll {
  opacity: 0;
  transition: opacity 500ms;
}
.js-scroll.scrolled {
  opacity: 1;
}
.scrolled.fade-in-right {
  animation: slide_right 0.8s ease;
}
.scrolled.fade-in-left {
  animation: slide_left 0.8s ease;
}
.scroll-element {
  height: 100%;
}

.scroll-element,
.scroll-caption {
  width: 100%;
}
.bm-menu-wrap {
  z-index: 100000000 !important;
  right: 0 !important;
  background-color: black;
  width: 70% !important;
  padding-top: 15%;
}
.bm-menu-wrap div nav a {
  font-family: "Nunito";
  margin-left: 15% !important;
  margin-top: 7% !important;
  font-size: 40px;
  color: white;
  transition: all 0.5s ease;
}
.bm-menu-wrap input {
  display: block;
  position: absolute;
  top: 2%;
  right: 5%;
  border: none;
  background: transparent;
  font-size: 47px;
  cursor: pointer;
  transition: all 0.5s ease;
}
.bm-menu-wrap input:hover,
.bm-menu-wrap div nav a:hover {
  transform: scale(1.1);
}
.bm-menu-wrap div nav a:focus-visible,
.bm-menu-wrap input:focus-visible {
  outline: none;
}
@keyframes slide_right {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes slide_left {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes scale_header_in {
  0% {
    background-color: transparent;
    height: 100px;
    border: 1px solid white;
  }
  100% {
    background-color: rgba(52, 52, 52, 0.77);
    height: 60px;
    border: none;
  }
}
@keyframes scale_header_out {
  0% {
    background-color: rgba(52, 52, 52, 0.77);
    height: 60px;
    border: none;
  }
  100% {
    background-color: transparent;
    height: 100px;
    border: 1px solid white;
  }
}
@keyframes fade_in {
  0% {
    opacity: 0;
    transform: translatey(-10px);
  }
  100% {
    opacity: 1;
    transform: translatey(0px);
  }
}
@keyframes fade_out {
  0% {
    opacity: 1;
    transform: translatey(0px);
  }
  100% {
    opacity: 0;
    transform: translatey(-10px);
  }
}
