@use "../../../flex_custom.scss" as *;
:root {
  --width-img-brush: 100%;
  --height-img-brush: 75%;
  --font-size-title: 3.5rem;
}
.row {
  @include center_center;
  flex-direction: row;
  height: 100vh;
}
.col_picture {
  @include center_center;
  width: 35vw;
  height: 100vh;
}
.row {
  @include center_center;
  flex-direction: row;
  height: 100vh;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  .col_description {
    @include center_start;
    flex-direction: column;
    width: 50vw;
    height: 100vh;
    margin-left: 5%;
    padding-right: 2%;
    h1 {
      width: 100%;
      font-family: "Permanent Marker";
      font-size: var(--font-size-title);
    }
    p {
      width: 100%;
      margin: 0px;
      padding: 0px;
      margin-left: 0%;
      font-size: 17px;
      color: rgb(255, 255, 255);
      font-family: "Nunito";
      font-weight: 600;
    }
    .row_odd {
      @include start_center;
      flex-direction: row;
      width: 100%;
    }
    .row_even {
      @include end_center;
      flex-direction: row;
      width: 100%;
      margin-right: 10%;
    }
    .row_buttons {
      .button_redirect_black {
        @include center_center;
        background-color: black;
        justify-content: space-evenly;
        flex-direction: row;
        width: 20%;
        height: 50px;
        margin-left: 0%;
        border: 3px solid white;
        user-select: none;
        cursor: pointer;
        margin-top: 3%;
        transition: background-color 0.5s;
        p {
          width: auto;
          margin: 0px;
          padding: 0px;
          font-weight: 800;
          color: white;
          transition: color 0.5s;
        }
        .icon_button_black {
          color: white;
          transition: color 0.5s;
        }
      }
      .button_redirect_black:hover {
        animation: scale_in 0.5s ease;
        background-color: white;
        transform: translateY(-6px);
        p {
          color: black;
          font-family: "Nunito";
        }
        .icon_button_black {
          color: black;
        }
      }
      .button_redirect_black:not(:hover) {
        animation: scale_out 0.5s ease;
        transform: translateY(0px);
        p {
          color: white;
        }
        .icon_button_black {
          color: white;
        }
      }
      .button_redirect_black:last-child {
        margin-left: 2%;
      }
      .button_redirect_white {
        @include center_center;
        background-color: white;
        justify-content: space-evenly;
        flex-direction: row;
        width: 20%;
        height: 50px;
        margin-left: 0%;
        border: 3px solid white;
        user-select: none;
        cursor: pointer;
        margin-top: 3%;
        transition: background-color 0.5s;
        .icons_social {
          font-size: 24px;
          color: black;
        }
        p {
          width: auto;
          margin: 0px;
          padding: 0px;
          font-weight: 800;
          color: black;
          transition: color 0.5s;
        }
        .icon_button_white {
          color: rgb(0, 0, 0);
          transition: color 0.5s;
        }
      }
      .button_redirect_white:hover {
        animation: scale_in 0.5s ease;
        background-color: rgb(0, 0, 0);
        transform: translateY(-6px);
        p {
          color: rgb(255, 255, 255);
        }
        .icon_button_white {
          color: white;
        }
      }
      .button_redirect_white:not(:hover) {
        animation: scale_out 0.5s ease;
        transform: translateY(0px);
        p {
          color: black;
        }
        .icon_button_white {
          color: black;
        }
      }
    }
  }
  .col_picture {
    @include center_center;
    width: 50vw;
    height: 100vh;
    position: relative;
    .img_brush_1 {
      background-image: url("../../../images/work/brush-1.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: var(--width-img-brush);
      height: var(--height-img-brush);
      padding-top: 1%;
    }
    .img_brush_2 {
      background-image: url("../../../images/work/brush-2.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: var(--width-img-brush);
      height: var(--height-img-brush);
    }
    .img_description {
      @include center_center;
      position: absolute;
      top: auto;
      bottom: auto;
      right: auto;
      left: auto;
      width: 350px;
      height: 180px;
      background-color: transparent;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      border: 5px solid white;
      box-shadow: 0px 12px 10px rgba(43, 43, 43, 0.267);
      transition-delay: 0.7s;
    }
    // for right
    .square_img_type_right_0 {
      left: 10%;
      top: 25%;
    }
    .square_img_type_right_1 {
      right: 10%;
      top: 20%;
    }
    .square_img_type_right_2 {
      right: 5%;
      bottom: 37%;
      z-index: 4;
    }
    .square_img_type_right_3 {
      left: 15%;
      bottom: 30%;
    }
    // for left
    .square_img_type_left_0 {
      left: 8%;
      top: 20%;
    }
    .square_img_type_left_1 {
      right: 18%;
      top: 25%;
      padding-left: 1%;
    }
    .square_img_type_left_2 {
      right: 13%;
      bottom: 30%;
    }
    .square_img_type_left_3 {
      left: 4%;
      bottom: 35%;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 950px) {
  :root {
    --width-img-brush: 40rem;
    --height-img-brush: 25rem;
    --font-size-title: 2.5rem;
  }
  .row:nth-child(even) {
    flex-direction: column;
    width: 100%;
  }
  .row:nth-child(odd) {
    flex-direction: column-reverse;
    width: 100%;
  }
  .col_description {
    width: 80vw !important;
    justify-content: flex-start;
    height: auto !important;
  }
  .row {
    height: auto;
  }
  .col_picture {
    display: none !important;
  }
  .row_buttons {
    justify-content: space-around !important;
    .button_redirect_white,
    .button_redirect_black {
      width: 50% !important;
    }
  }
}
@keyframes scale_in {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-6px);
  }
}
@keyframes scale_out {
  0% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes fade_in {
  0% {
    opacity: 0;
    transform: translatey(-10px);
  }
  100% {
    opacity: 1;
    transform: translatey(0px);
  }
}
@media only screen and (min-width: 200px) and (max-width: 600px) {
  .img_description {
    width: 31% !important;
    height: 17% !important;
  }
}
@media only screen and (min-width: 601px) and (max-width: 950px) {
  .img_description {
    width: 31% !important;
    height: 21% !important;
  }
}
@media only screen and (min-width: 951px) and (max-width: 1350px) {
  .col_picture {
    display: flex !important;
  }
  .img_description {
    width: 40% !important;
    height: 15% !important;
  }
}
