@use '../../flex_custom.scss' as *;
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Seaweed+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap');

.container{
    @include center_center;
    flex-direction: column;
    width: 100%;
    height: 15vh;
    border-top: 3px solid white;
    background-color: rgb(49, 49, 49);
    z-index: 2;
    .container_info{
        @include center_center;
        flex-direction: row;
        width: 100%;
        height: 50%;
        .bubble_contact{
            @include center_center;
            margin-left: 1%;
            margin-right: 1%;
            width: 40px;
            height: 40px;
            font-size: 17px;
            border: 2px solid white;
            border-radius: 500px;
            cursor: pointer;
        }
    }
    .container_rights{
        @include center_center;
        flex-direction: column;
        width: 100%;
        height: 40%;
        p{
            margin: 0;
            padding: 0;
            font-size: 13px;
            color: rgb(156, 156, 156);
            font-family: 'Nunito';
        }
    }
}
