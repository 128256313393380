@use '../../flex_custom.scss' as *;
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Seaweed+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap');

:root{
    --min-width-card: 19rem;
    --max-width-card: 19rem;
    --min-height-card: 12rem;
    --max-height-card: 12rem;
    --padding-top-cards: 4%;
    --font-size-title: 60px;
}
.container{
    @include center_center;
    width: 100%;
    height: auto;
    color: white;
    flex-direction: column;
    .container_title {
       @include center_center;
       flex-direction: column;
       width: 100%;
       height: auto;
       padding-top: var( --padding-top-cards);
       h1{
            width: 100%;
            font-family: 'Permanent Marker';
            font-size: var(--font-size-title);
            padding: 0;
            margin: 0;
            padding-left: 10%;
            animation: slide_left 0.5s ease;
        }
       .container_cards{
            @include center_start;
            flex-direction: row;
            flex-wrap: wrap;
            width: 95%;
            height: auto;
            padding-left: 1%;
            .card{
                @include center_center;
                flex-direction: column;
                min-width: var(--min-width-card);
                max-width: var(--min-width-card);
                height: var(--min-height-card);
                background-color: black;
                border: 3px solid white;
                margin: 1%;
                cursor: pointer;
                transition: background-color 0.5s ease;
                .card_img{
                    @include center_center;
                    width: 100%;
                    height: 60%;
                    border-bottom: 3px solid white;
                    transition: border-bottom 0.5s ease;
                    overflow: hidden;
                    img{
                        opacity: 0.4;
                        width: 100%;
                        background-size: cover;
                        transition: opacity 0.5s ease;
                    }
                }
                .card_title{
                    @include center_center;
                    color: white;
                    transition: color 0.5s ease;
                    width: 100%;
                    height: 40%;
                    h3{
                        font-family: "Nunito";
                        font-weight: 900;
                    }
                }
            }
            .card:hover{
                animation: scale_in 0.5s ease;
                background-color: white;
                transform: translateY(-6px);
                .card_title{
                    color: black;
                }
                .card_img{
                    border-bottom: 3px solid black;
                }
                .card_img{
                    img{
                        opacity: 1;
                    }
                }
            }
            .card:not(:hover){
                animation: scale_out 0.5s ease;
                transform: translateY(0px);
            }
        }
        .p_scroll{
            animation: color_infinite 2s ease infinite;
        }
        span{
            animation: color_infinite 3s ease infinite;
            font-family: "Nunito";
        }
    }
}

@media only screen and (min-width: 300px)  and (max-width: 414px) {
    :root{
        --min-width-card: 80%;
        --padding-top-cards: 30%;
        --font-size-title: 20px;
    }
}
@media only screen and (min-width: 415px)  and (max-width: 610px) {
    :root{
        --min-width-card: 80%;
        --padding-top-cards: 18%;
        --font-size-title: 30px;
    }
}
@media only screen and (min-width: 611px) and (max-width: 680px) {
    :root{
        --min-width-card: 80%;
        --padding-top-cards: 18%;
        --font-size-title: 30px;
    }
}
@media only screen and (min-width: 681px) and (max-width: 1040px) {
    :root{
        --padding-top-cards: 15%;
    }
}
@media only screen and (min-width: 1041px){
    :root{
        --padding-top-cards: 8%;
    }
}

@keyframes scale_in {
    0%{
        transform: translateY(0px);
    }
    100%{
        transform: translateY(-6px);
    }
}
@keyframes scale_out {
    0%{
        transform: translateY(-6px);
    }
    100%{
        transform: translateY(0px);
    }
}
@keyframes slide_left {
    0%{
        opacity: 0;
        transform: translateX(-300px);
    }
    100%{
        opacity: 1;
        transform: translateX(0px);
    }
}
@keyframes color_infinite {
    0%{
        color: rgb(247, 247, 247);
    }
    50%{
        color: rgb(141, 141, 141);
    }
    100%{
        color: rgb(247, 247, 247);
    }
}