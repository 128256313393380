@use "../flex_custom.scss" as *;

.container {
  @include start_center;
  flex-direction: column;
  width: 100%;
  height: auto;
  .container_slider {
    @include center_center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: #222222ef;
    position: fixed;
    z-index: 10000000000 !important;
    .container_button_esc {
      @include center_center;
      flex-direction: column;
      width: 45px;
      height: 60px;
      position: absolute;
      top: 5%;
      right: 5%;
      .button_escape {
        width: 100%;
        height: 100%;
        border-radius: 0;
        border: 3px solid white;
        background-color: black;
        color: white;
        transform: scale(1);
        transition: all 0.5s ease;
        &:hover {
          border: 3px solid black;
          background-color: white;
          transform: scale(1.1);
          color: black;
        }
      }
      label {
        color: white;
        font-family: "Nunito";
        font-size: 13px;
      }
    }
  }
}
.bg_1,
.bg_1_contact {
  background-image: url("../images/work/bg-1.png");
  z-index: 2;
  animation: slide_left 0.8s ease;
  opacity: 0.1;
}
.bg_2,
.bg_2_contact {
  background-image: url("../images/work/bg-2.png");
  z-index: 1;
  opacity: 1;
  animation: slide_left 0.8s ease;
  opacity: 0.07;
}
.bg_1,
.bg_2,
.bg_1_contact,
.bg_2_contact {
  width: 100%;
  height: 168vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: fixed;
  z-index: 0;
}
.bg_1_contact {
  left: -10%;
  top: 0;
}
.bg_2_contact {
  right: -10%;
  top: 0;
}
.bg_1_contact,
.bg_2_contact {
  width: 130vw !important;
}
@keyframes scale_in {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-6px);
  }
}
@keyframes scale_out {
  0% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(0px);
  }
}
