@use "../../../flex_custom.scss" as *;
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap");

.container {
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 350px;
  border: 5px solid white;
  background-color: black;
  h1 {
    color: white;
    text-align: center;
    font-family: "Nunito";
    font-weight: 900;
  }
  p {
    font-family: "Nunito";
  }
  .container_img {
    @include center_center;
    width: 20%;
    img {
      user-select: none;
      background-size: cover;
      height: 70%;
      margin-left: 30%;
    }
  }
  .info_card {
    width: 80%;
    user-select: auto;
    .row_info {
      @include space_between_start;
      padding-right: 7%;
      .title {
        font-weight: 900;
      }
      p {
        color: white;
        margin: 4% 0 0 10%;
      }
    }
  }
  .container_button {
    @include center_center;
  }
}
.button_redirect_white {
  @include center_center;
  background-color: white;
  justify-content: space-evenly;
  flex-direction: row;
  width: 90%;
  height: 50px;
  margin-left: 0%;
  border: 3px solid white;
  user-select: none;
  cursor: pointer;
  margin-top: 3%;
  transition: all 0.5s ease;
  text-decoration: none !important;
  p {
    width: auto;
    margin: 0px;
    padding: 0px;
    font-weight: 800;
    color: black;
    transition: color 0.5s;
  }
  .icon_button_white {
    color: rgb(0, 0, 0);
    transition: color 0.5s ease;
  }
}
.button_redirect_white:hover {
  animation: scale_in 0.5s ease;
  background-color: rgb(0, 0, 0);
  transform: translateY(-6px);
  p {
    color: rgb(255, 255, 255);
  }
  .icon_button_white {
    color: white;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1100px) {
  .container {
    width: 60%;
  }
}
@media only screen and (min-width: 200px) and (max-width: 500px) {
  .container_img img {
    height: 24% !important;
  }
  .info_card {
    font-size: 11px;
  }
  .button_redirect_white {
    height: 87%;
  }
  .container {
    height: 300px;
    h1 {
      font-size: 25px;
    }
  }
}
@media only screen and (max-width: 300px) {
  .container {
    width: 80%;
  }
}
@media only screen and (min-width: 200px) and (max-width: 799px) {
  .container {
    width: 80%;
  }
}
