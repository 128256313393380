@use "../../flex_custom.scss" as *;

.container {
  @include center_center;
  width: 100%;
  height: 100px;
  flex-direction: row;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 0px 0px 5px 5px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 90000;
  .img_logo {
    width: 65%;
    padding-left: 5%;
    img {
      width: 6%;
      transition: width 0.5s ease;
    }
  }
  nav {
    @include center_center;
    width: 35%;
    justify-content: space-evenly;
    .active_white {
      color: rgb(255, 255, 255);
      border-bottom: 3px solid currentColor;
      animation: scale 0.1s ease;
    }
    .active_black {
      color: rgb(0, 0, 0);
      border-bottom: 3px solid black;
      animation: scale 0.1s ease;
    }
    p {
      height: 25px;
      cursor: pointer;
      user-select: none;
      color: rgb(160, 160, 160);
    }
  }
}
@keyframes scale {
  0% {
    border-bottom: 0px solid currentColor;
    transform: scale(1);
  }
  100% {
    border-bottom: 3px solid currentColor;
    transform: scale(1.02);
  }
}
@media only screen and (max-width: 500px) {
  .img_logo {
    img {
      width: 16%;
    }
  }
}
