@use "../../../flex_custom.scss" as *;
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Seaweed+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap");

.container {
  @include start_center;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  z-index: 300;
  .img_bg_1 {
    background-image: url("../../../images/me/layer-1.png");
    width: 50%;
    z-index: 2;
    animation: slide_left 0.8s ease;
  }
  .img_bg_2 {
    background-image: url("../../../images/me/layer-2.png");
    width: 55%;
    z-index: 1;
    opacity: 1;
    animation: slide_left 0.8s ease;
  }
  .img_bg_3 {
    background-image: url("../../../images/me/layer-3.png");
    width: 50%;
    z-index: 3;
    animation: slide_left 0.8s ease;
    opacity: 0.7;
  }
  .img_bg_1,
  .img_bg_2,
  .img_bg_3 {
    left: -1%;
    top: -1%;
    height: 100vh;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
  }
  .column_info {
    @include center_start;
    flex-direction: column;
    width: 60%;
    height: 100%;
    z-index: 1000;
    h1 {
      font-size: 4.5rem;
      font-family: "Permanent Marker";
      color: rgb(255, 255, 255);
      margin: 0px;
      margin-top: 8%;
      padding-bottom: 40px;
      animation: slide_right 0.5s ease;
    }
    .text_description {
      font-weight: 600;
      animation: slide_right 0.9s ease;
    }
    .text_description_title {
      font-weight: 600;
      color: #686868 !important;
    }
    p {
      width: 80%;
      margin: 0px;
      padding: 0px;
      margin-left: 0%;
      font-size: 17px;
      color: rgb(255, 255, 255) !important;
      font-family: "Nunito";
    }
    .cont_buttons_social {
      @include start_center;
      width: 100%;
      height: 8%;
      margin-top: 5%;
      .button_redirect:first-child {
        margin-left: 0%;
        border: 3px solid rgb(55, 142, 255);
      }
      .button_redirect:last-child {
        border: 3px solid white;
      }
      .button_redirect {
        @include center_center;
        justify-content: space-evenly;
        flex-direction: row;
        width: 20%;
        height: 50px;
        margin-left: 5%;
        border: 3px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
        user-select: none;
        cursor: pointer;
        .icons_social {
          font-size: 24px;
        }
        p {
          width: auto;
          margin: 0px;
          padding: 0px;
        }
      }
      .button_redirect:hover {
        animation: scale_in 0.5s ease;
        transform: translateY(-6px);
      }
      .button_redirect:not(:hover) {
        animation: scale_out 0.5s ease;
        transform: translateY(0px);
      }
    }
  }
  .column_picture {
    @include center_center;
    width: 40%;
    height: 100%;
    z-index: 1000;
    .picture {
      width: 30%;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
@media only screen and (min-width: 300px) and (max-width: 745px) {
  :root {
    --width-img-brush: 40rem;
    --height-img-brush: 25rem;
    --font-size-title: 2.5rem;
  }
  .column_info {
    width: 100% !important;
    padding-left: 10%;
    h1 {
      font-size: 60px !important;
    }
  }
  .column_picture,
  .img_bg_1,
  .img_bg_2,
  .img_bg_3 {
    display: none !important;
  }
  .button_redirect {
    width: 25% !important;
  }
}
@keyframes scale_in {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-6px);
  }
}
@keyframes scale_out {
  0% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes slide_right {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes slide_left {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
